@font-face {
    font-family: 'EE-font';
    src: url('../assets/HavnText-Regular.woff2') format('woff2'),
        url('../assets/HavnText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html,
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    font-size: 14px;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 400px;
    height: 100%;
    display: flex;
    /* Add this */
    justify-content: center;
    /* Add this */
    align-items: center;
    /* Add this */
    background-color: rgb(255, 255, 255);
    font-family: 'EE-font',Arial, sans-serif;
    color: #1e212a;

    /* or */
    margin: 0 40px;
}

.left>* {
    padding: 0 0px;
    /* Add this line, adjust the value as needed */
}

.logoEE {
    margin-bottom: 20px;
    /* Add this line, adjust the value as needed */
    height: 80px;
}

.left h1 {
    font-size: 24px;
    color: #333;
    margin-top: 10px;
    margin-bottom: 30px;
}

.left p {
    margin-bottom: 10px;
}

.left ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.left button {
    width: 100%;
    /* Make the button the same width as the box */
    padding: 10px 20px;
    color: #fff;
    background-color: #0059E7;
    /* Change the button color */
    border: none;
    border-radius: 60px;
    cursor: pointer;
    margin-top: 10px;
    padding: 15px;
}

.left button:hover {
    background-color: #0041a8;
    /* Darken the button color when hovered */
}

.box {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    /* Add this line */
    text-align: left;
    margin-top: 20px;
}

.box ul {
    list-style-type: disc;
    padding-left: 20px;
    /* Add some left padding to the ul */
}

.box ul li {
    margin-bottom: 20px;
    /* Decrease the space between the bullet disc and the text */
}

.left .content {
    padding: 0 20px;
    /* Add this line, adjust the value as needed */
}


#consentText {
    color: #333;
    /* Change the text color */
    /* Change the font size */
}

#consentLink {
    color: #0059E7;
    /* Change the link color */
    text-decoration: none;
    /* Remove the underline from the link */
}

#consentLink:hover {
    text-decoration: underline;
    /* Add an underline to the link when hovered */
}

.right {
    background: url('../assets/EDUES_CONCEPT_BIG_2.jpeg') no-repeat top right;    background-size: cover;
    background-position: center;
    flex: 1;
    z-index: -1;
}



@media (max-width: 768px) {
    .right {
        display: none;
    }
}