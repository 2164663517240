.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e0dcd1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-box{
    background-color: white; 
    border-radius: 5px;
    width: 400px; 
    height: auto;
}

.modal-content {
    width: 100%;
    padding: 0px 39px 39px 39px;
    flex-direction: column;
    display: flex;
    align-items: center;
    border: none;
}


  .btn-blue {
    background-color: #0059E7;
    color: white;
    width: 100%;
    height: 52px;
    margin: 24px 0px 0px;
    padding: 4px 16px;
    border-radius: 30px;
    border: none;
}

.btn-white {
    background-color: white;
    width: 100%;
    height: 52px;
    margin: 24px 0px 0px;
    padding: 4px 16px;
    border-radius: 3px;
    border-color:rgba(0, 0, 0, 0.3);
    border-width: 1px;
    text-align: left;
}


.btn-close{
    width: 100%;
    display: flex;
    justify-content: right;
}

.text{
    padding: 20px 20px 0px 20px;
}


.textError{
    padding: 10px 20px 0px 20px;
    text-align: center;
}

.logo-ee{
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 20px 20px 0px 20px;
}

.delete-field{
    width: 100%;
    padding: 10px;                        
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
} 

.section-header{
    font-weight: bold;
    text-align: left !important;
    padding: 20px 20px 0px 20px;
    text-decoration-line: underline;
}