@font-face {
  font-family: 'EE-font';
  src: url('assets/HavnText-Regular.woff') format('woff2'),
    url('assets/HavnText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "EE-font", "ulp-font", "-apple-system", "BlinkMacSystemFont", "Roboto", "Helvetica", sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "EE-font", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

